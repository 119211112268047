import {combineReducers, configureStore} from '@reduxjs/toolkit';
import areas from './slices/Areas';
import areas_modal from './slices/AreaModalSlice';
import configuration from './slices/ConfigurationSlice';
import sites from './slices/Sites';
import nas_types from './slices/NasType';
import nas from './slices/Nas';

const reducer = combineReducers({
    areas,
    areas_modal,
    configuration,
    sites,
    nas_types,
    nas,
})

export default configureStore({reducer})