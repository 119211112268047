import React, { useState } from "react";
import {
    Flex,
    Td,
    Text,
    Tr,
    useColorModeValue,
    IconButton,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon, ViewIcon } from "@chakra-ui/icons";
import DeleteConfirmation from "components/Layout/DeleteModal";
import { useDispatch } from "react-redux";

const NaseTableData = (props) => {
    const { id, index, ip, name, secret, site, site_name, nas_type, nas_type_name, onEdit } = props;
    const textColor = useColorModeValue("gray.700", "white");
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showSecret, setShowSecret] = useState(false);
    const dispatch = useDispatch();

    const handleEdit = () => {
        dispatch(onEdit({ id, index, ip, name, secret, site, site_name, nas_type, nas_type_name }));
    };

    const handleDelete = () => {
        setShowDeleteConfirmation(true);
    };

    const handleCancelDelete = () => {
        setShowDeleteConfirmation(false);
    };

    const handleConfirmDelete = () => {
        console.log(`Deleting item with ID: ${id}`);
        setShowDeleteConfirmation(false);
    };

    const handleViewSecret = () => {
        setShowSecret(!showSecret);
    };

    return (
        <Tr>
            <Td>
                <Flex direction="column">
                    <Text fontSize="md" color={textColor} fontWeight="bold">
                        {index}
                    </Text>
                </Flex>
            </Td>
            <Td>
                <Flex direction="column">
                    <Text fontSize="md" color={textColor} fontWeight="bold">
                        {ip}
                    </Text>
                </Flex>
            </Td>

            <Td>
                <Flex direction="column">
                    <Text fontSize="md" color={textColor} fontWeight="bold">
                        {name}
                    </Text>
                </Flex>
            </Td>

            <Td>
                <Flex direction="column">
                    <Text fontSize="md" color={textColor} fontWeight="bold">
                        {showSecret ? secret : "*".repeat(secret.length)}
                    </Text>
                </Flex>
            </Td>

            <Td>
                <Flex direction="column">
                    <Text fontSize="md" color={textColor} fontWeight="bold">
                        {nas_type_name}
                    </Text>
                </Flex>
            </Td>

            <Td>
                <Flex direction="column">
                    <Text fontSize="md" color={textColor} fontWeight="bold">
                        {site_name}
                    </Text>
                </Flex>
            </Td>
            <Td>
                <IconButton
                    icon={<ViewIcon />}
                    colorScheme="telegram"
                    bgColor={showSecret ? "green.400" : "gray.400"}
                    color={"white"}
                    onClick={handleViewSecret}
                    aria-label="View Secret"
                    size="sm"
                    mr="3"
                />

                <IconButton
                    icon={<EditIcon />}
                    colorScheme="telegram"
                    bgColor="orange.400"
                    color={"white"}
                    onClick={handleEdit}
                    aria-label="Edit"
                    size="sm"
                    mr="3"
                />
                <IconButton
                    icon={<DeleteIcon />}
                    colorScheme="telegram"
                    bgColor="red"
                    color={"white"}
                    onClick={handleDelete}
                    aria-label="Delete"
                    size="sm"
                />
                {showDeleteConfirmation && (
                    <DeleteConfirmation
                        api={id}
                        id={id}
                        onClose={handleCancelDelete}
                        onDeleteSuccess={handleConfirmDelete}
                    />
                )}
            </Td>
        </Tr>
    );
};

export default NaseTableData;
