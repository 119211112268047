import axios from 'axios';
import {
    setIsLoading,
    setError,
    setSites,

    setParentOptions,
    setSelectedParent,
    setParentError,

    setNameError,

    setIsModalOpen,
    setSelectedData,
    setCurrentPage,
    setLastPage,
    setNextPage,
    setPrevPage,
    setIsEdit
} from '../slices/Sites';
import { baseurl } from 'variables';
import { setType } from 'redux/slices/AreaModalSlice';
import { setTypeError } from 'redux/slices/AreaModalSlice';
import { setTypeName } from 'redux/slices/AreaModalSlice';
import { setDisableParent } from 'redux/slices/AreaModalSlice';
import { setTypeOptions } from 'redux/slices/AreaModalSlice';





export const getSites = (currentPage, token) => async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
        const res = await axios.post(`${baseurl}/get-site`, { page: currentPage }, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
        console.log('Sites', res, currentPage);
        dispatch(setSites(res.data.data.Site));
        dispatch(setIsLoading(false));
        if (res.data.data.last_page === 1) {
            dispatch(setPrevPage(false));
            dispatch(setLastPage(false));
            dispatch(setNextPage(false));
        } else if (res.data.data.last_page === currentPage) {
            dispatch(setPrevPage(currentPage - 1));
            dispatch(setLastPage(res.data.data.last_page));
            dispatch((false));
        } else if (currentPage === 1) {
            dispatch(setPrevPage(false));
            dispatch(setLastPage(res.data.data.last_page));
            dispatch(setNextPage(currentPage + 1));
        } else {
            dispatch(setPrevPage(currentPage - 1));
            dispatch(setLastPage(res.data.data.last_page));
            dispatch(setNextPage(currentPage + 1));
        }

    } catch (error) {
        dispatch(setIsLoading(false));
        console.log('Sites error', error, currentPage);
        dispatch(setError(
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
                    ? error.message
                    : 'An unexpected error has occured. Please try again later'
        ));
    }
}



export const handleEdit = (data) => async (dispatch) => {
    console.log(`Edit data at site ${data}`);
    dispatch(setIsEdit(true));
    dispatch(setSelectedData(data));
    dispatch(setIsModalOpen(true));
};


export const handleDelete = (id) => async (dispatch) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this row?');
    if (confirmDelete) {
        console.log(`Deleting item at id ${id}`);
        // Perform the delete action here
    }
};

export const handleOpenModal = () => async (dispatch) => {
    console.log(`Open the modal`);
    dispatch(setIsModalOpen(true));
};


export const handleCloseModal = () => async (dispatch) => {
    dispatch(setSelectedData(null));
    dispatch(setIsModalOpen(false));
    dispatch(setIsEdit(false));
    dispatch(setParentOptions([]));
    dispatch(setSelectedParent(''));
    dispatch(setParentError(''));
    dispatch(setType(null));
    dispatch(setTypeOptions([]));
    dispatch(setTypeName(''));
};

export const getAreaList = (token, type) => async (dispatch) => {
    try {
        const response = await axios.post(
            `${baseurl}/specific-type-area?type=${parseInt(type.value)}`, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
        );
        console.log('Site Area parent response', response)
        const parentOptions = response.data.data.area.map((parent) => ({
            value: parent.id,
            label: parent.name,
        }));
        dispatch(setParentOptions(parentOptions));
        // dispatch(setSelectedParent(''));
    } catch (error) {
        dispatch(setParentOptions([]));
        dispatch(setSelectedParent(''));
        console.log(error);
    }
}

export const handleTypeChange = (selectedTypeOption, typeOptions) => async (dispatch) => {
    const selectedType = selectedTypeOption;
    dispatch(setType(selectedType));
    dispatch(setSelectedParent(''));
    // dispatch(setParentOptions([]));
    dispatch(setTypeName(selectedType.label));
};


export const handleParentChange = (selectedOption) => async (dispatch) => {
    dispatch(setSelectedParent(selectedOption));
    dispatch(setParentError(''));
};


export const handleSave = (typeName, selectedParent, isEdit, initialData, onClose, token) => async (dispatch) => {
    try {
        const name = document.getElementsByName('name')[0].value;


        if (!name) {
            dispatch(setNameError('Name is required'));
            return;
        } else {
            dispatch(setNameError(''));
        }
        let formData = {
            name: name,
        };


        if (!selectedParent) {
            dispatch(setParentError(`${typeName} is required`));
            return;
        } else {
            dispatch(setParentError(''));
            formData = {
                ...formData,
                area_id: selectedParent.value,
            };
        }

        console.log('save response', formData, isEdit, initialData);
        if (isEdit && initialData) {
            formData = {
                id: initialData.id,
                ...formData,
            };
            console.log('save response', formData, isEdit, initialData);
            const response = await axios.post(`${baseurl}/add-site`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            // Handle the response or perform any other necessary actions
            console.log('Response from SIte update', response.data);
        } else {
            const response = await axios.post(`${baseurl}/add-site`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            // Handle the response or perform any other necessary actions
            console.log(response.data);
        }

        // window.location.reload();
        dispatch(getSites(1, token));
        dispatch(onClose());
    } catch (error) {
        console.log('Error Sites Update', error);
    }
};