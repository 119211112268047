import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    isModalOpen: false,
    isLoading: true,
    error: null,
    selectedData: null,
    nas: [],

    nameError: '',
    ipError: '',
    secretError: '',

    selectedSite: '',
    siteError: '',
    SiteOptions: [],

    selectedNasType: '',
    nasTypeError: '',
    nasTypeOptions: [],

    currentPage: 1,
    nextPage: 0,
    prevPage: 0,
    lastPage: 0,
    isEdit: false,
};


export const nasSlice = createSlice({
    name: "nas",
    initialState,
    reducers: {
        setIsLoading: (state, { payload }) => {
            state.isLoading = payload;
        },
        setNas: (state, { payload }) => {
            state.nas = payload;
        },
        setError: (state, { payload }) => {
            state.error = payload;
        },

        setNameError: (state, { payload }) => {
            state.nameError = payload;
        },
        setIPError: (state, { payload }) => {
            state.ipError = payload;
        },
        setSecretError: (state, { payload }) => {
            state.secretError = payload;
        },

        setSiteOptions: (state, { payload }) => {
            state.SiteOptions = payload;
        },
        setSelectedSite: (state, { payload }) => {
            state.selectedSite = payload;
        },
        setSiteError: (state, { payload }) => {
            state.siteError = payload;
        },

        setNasTypeOptions: (state, { payload }) => {
            state.nasTypeOptions = payload;
        },
        setSelectedNasType: (state, { payload }) => {
            state.selectedNasType = payload;
        },
        setNasTypeError: (state, { payload }) => {
            state.nasTypeError = payload;
        },

        setIsModalOpen: (state, { payload }) => {
            state.isModalOpen = payload;
        },
        setSelectedData: (state, { payload }) => {
            state.selectedData = payload;
        },
        setCurrentPage: (state, { payload }) => {
            state.currentPage = payload;
        },
        setNextPage: (state, { payload }) => {
            state.nextPage = payload;
        },
        setPrevPage: (state, { payload }) => {
            state.prevPage = payload;
        },
        setLastPage: (state, { payload }) => {
            state.lastPage = payload;
        },
        setIsEdit: (state, { payload }) => {
            state.isEdit = payload;
        },

    }
})


export const { setIsLoading, setError, setNas, setNameError, setIPError, setSecretError, setSiteOptions, setSelectedSite, setSiteError, setNasTypeOptions, setSelectedNasType, setNasTypeError, setIsModalOpen, setSelectedData, setCurrentPage, setLastPage, setNextPage, setPrevPage, setIsEdit } = nasSlice.actions;
export default nasSlice.reducer;
export const nasSelector = (state) => state.nas;
