import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    isModalOpen: false,
    isLoading: true,
    error: null,
    selectedData: null,
    areas: [],
    currentPage: 1,
    nextPage: 0,
    prevPage: 0,
    lastPage: 0,
    isEdit: false,
};


export const areasSlice =  createSlice({
    name: "areas",
    initialState,
    reducers: {
        setIsLoading : (state, {payload}) => {
            state.isLoading = payload;
        },
        setAreas: (state, { payload }) => {
            state.areas = payload;
        },
        setError: (state, { payload }) => {
            state.error = payload;
        },
        setIsModalOpen: (state, { payload }) => {
            state.isModalOpen = payload;
        },
        setSelectedData: (state, { payload }) => {
            state.selectedData = payload;
        },
        setCurrentPage: (state, { payload }) => {
            state.currentPage = payload;
        },
        setNextPage: (state, { payload }) => {
            state.nextPage = payload;
        },
        setPrevPage: (state, { payload }) => {
            state.prevPage = payload;
        },
        setLastPage: (state, { payload }) => {
            state.lastPage = payload;
        },
        setIsEdit: (state, { payload }) => {
            state.isEdit = payload;
        },
        
    }
})


export const { setIsLoading, setError, setAreas, setIsModalOpen, setSelectedData, setCurrentPage, setLastPage, setNextPage, setPrevPage, setIsEdit } = areasSlice.actions;
export default areasSlice.reducer;

export const areasSelector = (state) => state.areas;