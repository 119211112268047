import axios from 'axios';
import {
    setIsLoading,
    setError,
    setNas, 

    setNameError, 
    setIPError, 
    setSecretError, 

    setSiteOptions, 
    setSelectedSite, 
    setSiteError, 

    setNasTypeOptions, 
    setSelectedNasType, 
    setNasTypeError, 

    setIsModalOpen,
    setSelectedData,
    setCurrentPage,
    setLastPage,
    setNextPage,
    setPrevPage,
    setIsEdit
} from '../slices/Nas';
import { baseurl } from 'variables';



export const getNas = (currentPage, token) => async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
        const res = await axios.post(`${baseurl}/get-nas`, { page: currentPage }, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
        console.log('Nas', res, currentPage);
        dispatch(setNas(res.data.data.nas));
        dispatch(setIsLoading(false));
        if (res.data.data.last_page === 1) {
            dispatch(setPrevPage(false));
            dispatch(setLastPage(false));
            dispatch(setNextPage(false));
        } else if (res.data.data.last_page === currentPage) {
            dispatch(setPrevPage(currentPage - 1));
            dispatch(setLastPage(res.data.data.last_page));
            dispatch((false));
        } else if (currentPage === 1) {
            dispatch(setPrevPage(false));
            dispatch(setLastPage(res.data.data.last_page));
            dispatch(setNextPage(currentPage + 1));
        } else {
            dispatch(setPrevPage(currentPage - 1));
            dispatch(setLastPage(res.data.data.last_page));
            dispatch(setNextPage(currentPage + 1));
        }

    } catch (error) {
        dispatch(setIsLoading(false));
        console.log('Nas error', error, currentPage);
        dispatch(setError(
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
                    ? error.message
                    : 'An unexpected error has occured. Please try again later'
        ));
    }
}

export const handleEdit = (data) => async (dispatch) => {
    console.log(`Edit data at Nas ${data}`);
    dispatch(setIsEdit(true));
    dispatch(setSelectedData(data));
    dispatch(setIsModalOpen(true));
};

export const handleDelete = (id) => async (dispatch) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this row?');
    if (confirmDelete) {
        console.log(`Deleting item at id ${id}`);
        // Perform the delete action here
    }
};


export const handleOpenModal = () => async (dispatch) => {
    console.log(`Open the modal`);
    dispatch(setIsModalOpen(true));
};

export const handleCloseModal = () => async (dispatch) => {
    dispatch(setSelectedData(null));
    dispatch(setIsModalOpen(false));
    dispatch(setIsEdit(false));

    dispatch(setNameError(''));
    dispatch(setIPError(''));
    dispatch(setSecretError(''));

    dispatch(setSiteOptions([]));
    dispatch(setSelectedSite(''));
    dispatch(setSiteError(''));

    dispatch(setNasTypeOptions([]));
    dispatch(setSelectedNasType(''));
    dispatch(setNasTypeError(''));
};


export const handleSiteChange = (selectedOption) => async (dispatch) => {
    dispatch(setSelectedSite(selectedOption));
    dispatch(setSiteError(''));
};



export const handleNasTypeChange = (selectedOption) => async (dispatch) => {
    dispatch(setSelectedNasType(selectedOption));
    dispatch(setNasTypeError(''));
};


export const getSiteList = (token) => async (dispatch) => {
    try {
        const response = await axios.get(
            `${baseurl}/get-site-all`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }
        );
        const SiteOptions = response.data.data.Site.map((Site) => ({
            value: Site.id,
            label: Site.name,
        }));
        console.log('Site Area parent response', response, SiteOptions)
        dispatch(setSiteOptions(SiteOptions));
    } catch (error) {
        dispatch(setSiteOptions([]));
        dispatch(setSelectedSite(''));
        console.log(error);
    }
}


export const getNasTypeList = (token) => async (dispatch) => {
    try {
        const response = await axios.get(
            `${baseurl}/get-nas-type-all`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }
        );
        console.log('Site Area parent response', response)
        const nasTypeOptions = response.data.data.nas_type//.map((Site) => ({
        //     value: Site.id,
        //     label: Site.name,
        // }));
        dispatch(setNasTypeOptions(nasTypeOptions));
    } catch (error) {
        dispatch(setNasTypeOptions([]));
        dispatch(setSelectedNasType(''));
        console.log(error);
    }
}


export const handleSave = (selectedSite, selectedNasType, isEdit, initialData, onClose, token) => async (dispatch) => {
    try {
        const name = document.getElementsByName('name')[0].value;
        const ip = document.getElementsByName('ip')[0].value;
        const secret = document.getElementsByName('secret')[0].value;


        if (!name) {
            dispatch(setNameError('Name is required'));
            return;
        } else {
            dispatch(setNameError(''));
        }

        if (!ip) {
            dispatch(setIPError('IP is required'));
            return;
        } else {
            dispatch(setIPError(''));
        }


        if (!secret) {
            dispatch(setSecretError('Secret is required'));
            return;
        } else {
            dispatch(setSecretError(''));
        }

        let formData = {
            name: name,
            ip: name,
            secret: name,
        };

        if (!selectedNasType) {
            dispatch(setNasTypeError(`Nas Type is required`));
            return;
        } else {
            dispatch(setNasTypeError(''));
            formData = {
                ...formData,
                nas_type_id: selectedNasType.value,
            };
        }


        if (!selectedSite) {
            dispatch(setSiteError(`Site is required`));
            return;
        } else {
            dispatch(setSiteError(''));
            formData = {
                ...formData,
                site_id: selectedSite.value,
            };
        }

        console.log('save response', formData, isEdit, initialData);
        if (isEdit && initialData) {
            formData = {
                id: initialData.id,
                ...formData,
            };
            console.log('save response', formData, isEdit, initialData);
            const response = await axios.post(`${baseurl}/add-nas`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            // Handle the response or perform any other necessary actions
            console.log('Response from Nas update', response.data);
        } else {
            const response = await axios.post(`${baseurl}/add-nas`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            // Handle the response or perform any other necessary actions
            console.log(response.data);
        }

        // window.location.reload();
        dispatch(getNas(1, token));
        dispatch(onClose());
    } catch (error) {
        console.log('Error Nas Update', error);
    }
};