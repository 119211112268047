import axios from 'axios';
import { baseurl } from '../../variables';

import {
    setIsLoading,
    setError,
    setAreas,
    setIsModalOpen,
    setSelectedData,
    setCurrentPage,
    setLastPage,
    setNextPage,
    setPrevPage,
    setIsEdit,
} from '../slices/Areas';

import {
    setType,
    setParentOptions,
    setTypeOptions,
    setDisableParent,
    setSelectedParent,
    setTypeName,
    setNameError,
    setTypeError,
    setParentError
} from '../slices/AreaModalSlice';

// For open the modal
export const handleOpenModal = () => async (dispatch) => {
    console.log(`Open the modal`);
    dispatch(setIsModalOpen(true));
};
// For delete the modal
export const handleCloseModal = () => async (dispatch) => {
    dispatch(setSelectedData(null));
    dispatch(setIsModalOpen(false));
    dispatch(setIsEdit(false));
    dispatch(setType(null));
    dispatch(setParentOptions([]));
    dispatch(setTypeOptions([]));
    dispatch(setTypeName(''));
};

export const handleEdit = (data) => async (dispatch) => {
    console.log(`Edit data at ${data}`);
    dispatch(setIsEdit(true));
    dispatch(setSelectedData(data));
    dispatch(setIsModalOpen(true));
};

export const handleDelete = (id) => async (dispatch) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this row?');
    if (confirmDelete) {
        console.log(`Deleting item at id ${id}`);
        // Perform the delete action here
    }
};


export const getAreas = (currentPage, token) => async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
        const res = await axios.post(`${baseurl}/get-area`, { page: currentPage },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
        console.log('area', res, currentPage);
        dispatch(setAreas(res.data.data.area));
        dispatch(setIsLoading(false));
        if (res.data.data.last_page === 1) {
            dispatch(setPrevPage(false));
            dispatch(setLastPage(false));
            dispatch(setNextPage(false));
        } else if (res.data.data.last_page === currentPage) {
            dispatch(setPrevPage(currentPage - 1));
            dispatch(setLastPage(res.data.data.last_page));
            dispatch((false));
        } else if (currentPage === 1) {
            dispatch(setPrevPage(false));
            dispatch(setLastPage(res.data.data.last_page));
            dispatch(setNextPage(currentPage + 1));
        } else {
            dispatch(setPrevPage(currentPage - 1));
            dispatch(setLastPage(res.data.data.last_page));
            dispatch(setNextPage(currentPage + 1));
        }

    } catch (error) {
        dispatch(setIsLoading(false));
        console.log(error)
        dispatch(setError(
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
                    ? error.message
                    : 'An unexpected error has occured. Please try again later'
        ));
    }
}