import { Button, FormControl, FormErrorMessage, FormLabel, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useColorMode } from "@chakra-ui/react";
import selectStyles from "components/Layout/StyleSelectNew";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectNew from 'react-select';
import { fetchTypeAreas } from "redux/actions/AreaModalAction";
import { handleTypeChange } from "redux/actions/SiteAction";
import { handleSave } from "redux/actions/SiteAction";
import { getAreaList, handleParentChange } from "redux/actions/SiteAction";
import { setTypeName } from "redux/slices/AreaModalSlice";
import { setType } from "redux/slices/AreaModalSlice";
import { setSelectedParent } from "redux/slices/Sites";

const ModalSite = ({ isOpen, onClose, isEdit, initialData }) => {
    const dispatch = useDispatch();
    const { colorMode } = useColorMode();
    const isDarkMode = colorMode === 'dark';
    const styles = selectStyles(isDarkMode, colorMode);
    const configurationList = useSelector((state) => state.configuration);
    const {
        configuration,
        token
    } = configurationList;

    const areaModalList = useSelector((state) => state.areas_modal);
    const {
        type,
        typeOptions,
        typeName,
        typeError
    } = areaModalList;


    const siteModalList = useSelector((state) => state.sites);

    const {
        selectedParent,
        parentError,
        parentOptions,
        nameError,
    } = siteModalList;

    useEffect(() => {
        dispatch(fetchTypeAreas(token));
    }, []);

    useEffect(() => {
        if (type)
        {
            dispatch(getAreaList(token, type));
        }
    }, [type]);

    useEffect(() => {
        console.log('edit records sites', initialData, isEdit)
        if (isEdit && initialData) {
            dispatch(setType({
                value: initialData.type,
                label: initialData.type_name
            }));
            dispatch(setSelectedParent({
                value: initialData.parent,
                label: initialData.parent_name
            }));
            dispatch(setTypeName(initialData.type_name))
        }
    }, [isEdit, initialData]);


    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{isEdit ? 'Edit Site' : 'Create Site'}</ModalHeader>
                {/* <ModalCloseButton /> */}
                <ModalBody>

                    <FormControl mt={4} isRequired isInvalid={nameError !== ''}>
                        <FormLabel>Name</FormLabel>
                        <Input type="text" placeholder="Enter name" name="name" defaultValue={initialData?.name} />
                        <FormErrorMessage>{nameError}</FormErrorMessage>
                    </FormControl>

                    <FormControl mt={4} isRequired isInvalid={typeError !== ''}>
                        <FormLabel>Type</FormLabel>
                        <SelectNew
                            classNamePrefix="react-select"
                            closeMenuOnSelect={false}
                            options={typeOptions}
                            styles={styles}
                            name="status"
                            value={type}
                            onChange={(value) => dispatch(handleTypeChange(value, typeOptions))}
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    neutral0: colorMode === 'dark' ? 'gray.800' : 'white',
                                },
                            })}
                        />
                        <FormErrorMessage>{typeError}</FormErrorMessage>
                    </FormControl>

                    <FormControl mt={4} isRequired isInvalid={parentError !== ''}>
                        <FormLabel>{typeName}</FormLabel>
                        <SelectNew
                            classNamePrefix="react-select"
                            closeMenuOnSelect={false}
                            options={parentOptions}
                            styles={styles}
                            name="parent_area"
                            value={selectedParent}
                            onChange={(value) => dispatch(handleParentChange(value))}
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    neutral0: colorMode === 'dark' ? 'gray.800' : 'white',
                                },
                            })}
                        />
                        <FormErrorMessage>{parentError}</FormErrorMessage>
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button
                        mr={3}
                        colorScheme="telegram"
                        color={"white"}
                        bgColor={configuration.color}
                        onClick={() => dispatch(handleSave(typeName, selectedParent, isEdit, initialData, onClose, token))}
                    >
                        {isEdit ? 'Update' : 'Save'}
                    </Button>
                    <Button variant="ghost" onClick={() => dispatch(onClose())} colorScheme="red">
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
};

export default ModalSite;