import axios from 'axios';
import {
    setIsLoading,
    setError,
    setNasType,

    setNameError,

    setIsModalOpen,
    setSelectedData,
    setCurrentPage,
    setLastPage,
    setNextPage,
    setPrevPage,
    setIsEdit
} from '../slices/NasType';
import { baseurl } from 'variables';

export const getNasType = (currentPage, token) => async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
        const res = await axios.post(`${baseurl}/get-nas-type`, { page: currentPage }, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
        console.log('Nas Types', res, currentPage);
        dispatch(setNasType(res.data.data.nas_type));
        dispatch(setIsLoading(false));
        if (res.data.data.last_page === 1) {
            dispatch(setPrevPage(false));
            dispatch(setLastPage(false));
            dispatch(setNextPage(false));
        } else if (res.data.data.last_page === currentPage) {
            dispatch(setPrevPage(currentPage - 1));
            dispatch(setLastPage(res.data.data.last_page));
            dispatch((false));
        } else if (currentPage === 1) {
            dispatch(setPrevPage(false));
            dispatch(setLastPage(res.data.data.last_page));
            dispatch(setNextPage(currentPage + 1));
        } else {
            dispatch(setPrevPage(currentPage - 1));
            dispatch(setLastPage(res.data.data.last_page));
            dispatch(setNextPage(currentPage + 1));
        }

    } catch (error) {
        dispatch(setIsLoading(false));
        console.log('Nas Type error', error, currentPage);
        dispatch(setError(
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
                    ? error.message
                    : 'An unexpected error has occured. Please try again later'
        ));
    }
}

export const handleEdit = (data) => async (dispatch) => {
    console.log(`Edit data at site ${data}`);
    dispatch(setIsEdit(true));
    dispatch(setSelectedData(data));
    dispatch(setIsModalOpen(true));
};


export const handleDelete = (id) => async (dispatch) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this row?');
    if (confirmDelete) {
        console.log(`Deleting item at id ${id}`);
        // Perform the delete action here
    }
};

export const handleOpenModal = () => async (dispatch) => {
    console.log(`Open the modal`);
    dispatch(setIsModalOpen(true));
};


export const handleCloseModal = () => async (dispatch) => {
    dispatch(setSelectedData(null));
    dispatch(setIsModalOpen(false));
    dispatch(setIsEdit(false));
};


export const handleSave = (isEdit, initialData, onClose, token) => async (dispatch) => {
    try {
        const name = document.getElementsByName('name')[0].value;


        if (!name) {
            dispatch(setNameError('Name is required'));
            return;
        } else {
            dispatch(setNameError(''));
        }
        let formData = {
            type: name,
        };


        console.log('save response', formData, isEdit, initialData);
        if (isEdit && initialData) {
            formData = {
                id: initialData.id,
                ...formData,
            };
            console.log('save response', formData, isEdit, initialData);
            const response = await axios.post(`${baseurl}/add-nas-type`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            // Handle the response or perform any other necessary actions
            console.log('Response from Nas Type update', response.data);
        } else {
            const response = await axios.post(`${baseurl}/add-nas-type`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            // Handle the response or perform any other necessary actions
            console.log(response.data);
        }

        // window.location.reload();
        dispatch(getNasType(1, token));
        dispatch(onClose());
    } catch (error) {
        console.log('Error Nas Type Update', error);
    }
};