// Chakra imports
import { Flex } from "@chakra-ui/react";
import React from "react";
import NasView from "./components/NasView";

function Nas() {

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <NasView
          title={"Nas"}
          captions={["Sr.", "IP", "Name", "Secrets", "Nas Type", "Site", "Actions"]}
        />
    </Flex>
  );
}

export default Nas;
