import { useEffect, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    FormControl,
    FormLabel,
    Select,
    Input,
    Button,
    useColorMode,
    FormErrorMessage,
} from '@chakra-ui/react';
import SelectNew from 'react-select';
import axios from 'axios';
import selectStyles from 'components/Layout/StyleSelectNew';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchParentAreas,
    handleTypeChange,
    handleParentChange,
    handleSave,
    fetchTypeAreas,
} from '../../../../redux/actions/AreaModalAction';

import { setType, setSelectedParent, setTypeName, setDisableParent } from '../../../../redux/slices/AreaModalSlice';


const ModalArea = ({ isOpen, onClose, isEdit, initialData }) => {
    const dispatch = useDispatch();
    const { colorMode } = useColorMode();
    const areaModalList = useSelector((state) => state.areas_modal);
    const {
        type,
        parentOptions,
        typeOptions,
        disableParent,
        // selectedType,
        selectedParent,
        typeName,
        nameError,
        typeError,
        parentError } = areaModalList;

    const isDarkMode = colorMode === 'dark';
    const styles = selectStyles(isDarkMode, colorMode);

    const configurationList = useSelector((state) => state.configuration);
    const {
        configuration, token
    } = configurationList;

    useEffect(() => {
        dispatch(fetchTypeAreas(token));
    }, []);


    useEffect(() => {
        dispatch(fetchParentAreas(type, token));
    }, [type]);


    useEffect(() => {
        console.log('edit records area', initialData, isEdit)
        if (isEdit && initialData) {
            dispatch(setType({
                value: initialData.type_id,
                label: initialData.type_name
            }));
            dispatch(setSelectedParent({
                value: initialData.parent_id,
                label: initialData.parent_name
            }));
            dispatch(setTypeName(initialData.type_name));
        }
    }, [isEdit, initialData]);


    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{isEdit ? 'Edit Area' : 'Create Area'}</ModalHeader>
                {/* <ModalCloseButton /> */}
                <ModalBody>
                    <FormControl mt={4} isRequired isInvalid={typeError !== ''}>
                        <FormLabel>Type</FormLabel>
                        <SelectNew
                            classNamePrefix="react-select"
                            closeMenuOnSelect={false}
                            options={typeOptions}
                            styles={styles}
                            name="status"
                            value={type}
                            onChange={(value) => dispatch(handleTypeChange(value, typeOptions))}
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    neutral0: colorMode === 'dark' ? 'gray.800' : 'white',
                                },
                            })}
                        />
                        <FormErrorMessage>{typeError}</FormErrorMessage>
                    </FormControl>
                    {!disableParent && (
                        <FormControl mt={4} isRequired isInvalid={parentError !== ''}>
                            <FormLabel>{typeName}</FormLabel>
                            <SelectNew
                                classNamePrefix="react-select"
                                closeMenuOnSelect={false}
                                options={parentOptions}
                                isDisabled={disableParent}
                                styles={styles}
                                name="parent_area"
                                value={selectedParent}
                                onChange={(value) => dispatch(handleParentChange(value))}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        neutral0: colorMode === 'dark' ? 'gray.800' : 'white',
                                    },
                                })}
                            />
                            <FormErrorMessage>{parentError}</FormErrorMessage>
                        </FormControl>
                    )}
                    <FormControl mt={4} isRequired isInvalid={nameError !== ''}>
                        <FormLabel>Name</FormLabel>
                        <Input type="text" placeholder="Enter name" name="name" defaultValue={initialData?.name} />
                        <FormErrorMessage>{nameError}</FormErrorMessage>
                    </FormControl>

                </ModalBody>
                <ModalFooter>
                    <Button
                        mr={3}
                        colorScheme="telegram"
                        color={"white"}
                        bgColor={configuration.color}
                        onClick={() => dispatch(handleSave(selectedParent, type, isEdit, initialData, onClose, token))}
                    >
                        {isEdit ? 'Update' : 'Save'}
                    </Button>
                    <Button variant="ghost" onClick={() => dispatch(onClose())} colorScheme="red">
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ModalArea;
