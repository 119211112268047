import {
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Td,
  useColorModeValue,
  Button,
  Flex,
  Spinner,
  InputGroup,
  Input,
  InputRightElement,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ModalArea from "./AreaModal";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import TablesTableRow from "components/Tables/TablesTableRow";
import { useDispatch, useSelector } from 'react-redux';
import {
  handleOpenModal,
  handleEdit,
  getAreas,
  handleDelete,
  handleCloseModal,
} from '../../../../redux/actions/AreaAction';

import {
  setCurrentPage
} from '../../../../redux/slices/Areas';

import { getConfiguration } from "../../../../redux/actions/ConfigurationAction";
import { setConfiguration } from "../../../../redux/slices/ConfigurationSlice";

const Authors = ({ title, captions }) => {
  const dispatch = useDispatch();
  const textColor = useColorModeValue("gray.700", "white");
  const areaList = useSelector((state) => state.areas);
  const {
    isModalOpen,
    isLoading,
    error,
    selectedData,
    areas,
    currentPage,
    nextPage,
    prevPage,
    lastPage,
    isEdit
  } = areaList;

  const configurationList = useSelector((state) => state.configuration);
  const {
    configuration,
    token
  } = configurationList;

  useEffect(() => {
    dispatch(getAreas(currentPage, token));
  }, [currentPage, dispatch]);

  return (
    <>
      {isLoading ? ( // Display loading spinner if data is still being fetched
        <Flex justify="center" align="center" height="300px">
          <Spinner size="xl" color="gray.400" />
        </Flex>
      ) : (
        <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
          <CardHeader p='6px 0px 22px 0px'>
            <Flex alignItems='center' justifyContent='space-between' flex={1}>
              <Text fontSize='xl' color={textColor} fontWeight='bold'>
                {title}
              </Text>
              <Button onClick={() => dispatch(handleOpenModal())} colorScheme='telegram' color={"white"} bgColor={configuration.color} mr='3px'>
                Add New Area
              </Button>
            </Flex>
          </CardHeader>
          <CardBody>

            <Table variant='simple' color={textColor}>
              <Thead>
                <Tr>
                  <Td colSpan={5} pl="0px">
                    <Flex justifyContent={"flex-end"}>
                      <Input
                        width={"30%"}
                        mr={"0.5rem"}
                        type="text"
                        placeholder="Search"
                      />
                      <Button
                        colorScheme="telegram"
                        color={"white"}
                        bgColor={configuration.color}
                      >
                        Search
                      </Button>
                    </Flex>
                  </Td>
                </Tr>

                <Tr my='.8rem' pl='0px' color='gray.400'>
                  {captions.map((caption, idx) => {
                    return (
                      <Th color='gray.400' key={idx} ps={idx === 0 ? "0px" : null}>
                        {caption}
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {areas.map((row, index) => {
                  return (
                    <TablesTableRow
                      key={row.id}
                      index={index + 1}
                      name={row.name}
                      parent_id={row.parent}
                      parent_name={row.parent_name}
                      id={row.id}
                      type_id={row.type}
                      type_name={row.type_name}
                      onEdit={handleEdit}
                    />
                  );
                })}
                <Tr>
                  <Td colSpan={5} pl="0px">
                    <Flex justifyContent="flex-end" alignItems="center">

                      {prevPage !== false && <Button colorScheme='telegram' bgColor={configuration.color} mr='1rem' onClick={() => dispatch(setCurrentPage(currentPage - 1))}>
                        <Text
                          fontSize="md"
                          color="white"
                          fontWeight="bold"
                          cursor="pointer"
                        >
                          Prev
                        </Text>
                      </Button>
                      }
                      <Text fontSize="md" color={textColor} fontWeight="bold" pr="1rem">
                        Page No: {currentPage}
                      </Text>
                      {nextPage !== false && <Button colorScheme='telegram' bgColor={configuration.color} onClick={() => dispatch(setCurrentPage(currentPage + 1))}>
                        <Text
                          fontSize="md"
                          color="white"
                          fontWeight="bold"
                          cursor="pointer"
                        >
                          Next
                        </Text>
                      </Button>
                      }
                    </Flex>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </CardBody>


          {isModalOpen && <ModalArea isOpen={isModalOpen} onClose={handleCloseModal} initialData={selectedData} isEdit={isEdit} />}
        </Card>
      )}
    </>
  );
};

export default Authors;
