import { Button, FormControl, FormErrorMessage, FormLabel, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useColorMode } from "@chakra-ui/react";
import selectStyles from "components/Layout/StyleSelectNew";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectNew from 'react-select';
import { handleSave } from "redux/actions/NasAction";
import { getSiteList } from "redux/actions/NasAction";
import { handleNasTypeChange } from "redux/actions/NasAction";
import { getNasTypeList } from "redux/actions/NasAction";
import { handleSiteChange } from "redux/actions/NasAction";
// import { handleSave } from "redux/actions/SiteAction";
import { setSelectedNasType } from "redux/slices/Nas";
import { setSelectedSite } from "redux/slices/Nas";


const ModalNas = ({ isOpen, onClose, isEdit, initialData }) => {
    const dispatch = useDispatch();
    const { colorMode } = useColorMode();
    const isDarkMode = colorMode === 'dark';
    const styles = selectStyles(isDarkMode, colorMode);
    const configurationList = useSelector((state) => state.configuration);
    const {
        configuration,
        token
    } = configurationList;

    const nasModalList = useSelector((state) => state.nas);

    const {
        nameError,
        ipError,
        secretError,

        selectedSite,
        siteError,
        SiteOptions,

        selectedNasType,
        nasTypeError,
        nasTypeOptions,
    } = nasModalList;


    useEffect(() => {
        dispatch(getNasTypeList(token));        
        dispatch(getSiteList(token));
    }, []);

    useEffect(() => {
        console.log('edit records nas', initialData, isEdit)
        if (isEdit && initialData) {
            dispatch(setSelectedSite({
                value: initialData.site,
                label: initialData.site_name
            }));
            dispatch(setSelectedNasType({
                value: initialData.nas_type,
                label: initialData.nas_type_name
            }));

        }
    }, [isEdit, initialData]);

    console.log('nas collections', SiteOptions, nasTypeOptions)
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{isEdit ? 'Edit Nas' : 'Create Nas'}</ModalHeader>
                {/* <ModalCloseButton /> */}
                <ModalBody>
                    <FormControl mt={4} isRequired isInvalid={nameError !== ''}>
                        <FormLabel>Name</FormLabel>
                        <Input type="text" placeholder="Enter name" name="name" defaultValue={initialData?.name} />
                        <FormErrorMessage>{nameError}</FormErrorMessage>
                    </FormControl>

                    <FormControl mt={4} isRequired isInvalid={ipError !== ''}>
                        <FormLabel>IP</FormLabel>
                        <Input type="text" placeholder="Enter IP" name="ip" defaultValue={initialData?.ip} />
                        <FormErrorMessage>{ipError}</FormErrorMessage>
                    </FormControl>

                    <FormControl mt={4} isRequired isInvalid={secretError !== ''}>
                        <FormLabel>Secret</FormLabel>
                        <Input type="text" placeholder="Enter Secret" name="secret" defaultValue={initialData?.secret} />
                        <FormErrorMessage>{secretError}</FormErrorMessage>
                    </FormControl>



                    <FormControl mt={4} isRequired isInvalid={nasTypeError !== ''}>
                        <FormLabel>Nas Type</FormLabel>
                        <SelectNew
                            classNamePrefix="react-select"
                            closeMenuOnSelect={false}
                            options={nasTypeOptions}
                            styles={styles}
                            name="nas_type"
                            value={selectedNasType}
                            onChange={(value) => dispatch(handleNasTypeChange(value))}
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    neutral0: colorMode === 'dark' ? 'gray.800' : 'white',
                                },
                            })}
                        />
                        <FormErrorMessage>{nasTypeError}</FormErrorMessage>
                    </FormControl>

                    <FormControl mt={4} isRequired isInvalid={siteError !== ''}>
                        <FormLabel>Site</FormLabel>
                        <SelectNew
                            classNamePrefix="react-select"
                            closeMenuOnSelect={false}
                            options={SiteOptions}
                            styles={styles}
                            name="site"
                            value={selectedSite}
                            onChange={(value) => dispatch(handleSiteChange(value))}
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    neutral0: colorMode === 'dark' ? 'gray.800' : 'white',
                                },
                            })}
                        />
                        <FormErrorMessage>{siteError}</FormErrorMessage>
                    </FormControl>

                </ModalBody>
                <ModalFooter>
                    <Button
                        mr={3}
                        colorScheme="telegram"
                        color={"white"}
                        bgColor={configuration.color}
                        onClick={() => dispatch(handleSave(selectedSite, selectedNasType, isEdit, initialData, onClose, token))}
                    >
                        {isEdit ? 'Update' : 'Save'}
                    </Button>
                    <Button variant="ghost" onClick={() => dispatch(onClose())} colorScheme="red">
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
};

export default ModalNas;