import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    configuration: localStorage.getItem('configuration') ? JSON.parse(localStorage.getItem('configuration')) : {"color":"red.300", "base_url": "https://blog.hubspot.com/", "primary_logo": "hubfs/image8-2.jpg", "called": true},
    // configuration: {"color":"red.300", "base_url": "https://blog.hubspot.com/", "primary_logo": "hubfs/image8-2.jpg", "called": true},
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
    token: localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null,
    usernameError: '', 
    passwordError: ''
};

export const confSlice =  createSlice({
    name: "configuration",
    initialState,
    reducers: {
        setConfiguration : (state, {payload}) => {
            state.configuration = payload;
        },
        setUser : (state, {payload}) => {
            state.user = payload;
        },
        setToken : (state, {payload}) => {
            state.token = payload;
        },
        setUsernameError : (state, {payload}) => {
            state.usernameError = payload;
        },
        setPasswordError : (state, {payload}) => {
            state.passwordError = payload;
        },

    }
})



export const { setConfiguration, setUser, setToken, setUsernameError, setPasswordError } = confSlice.actions;
export default confSlice.reducer;

export const confSelector = (state) => state.configuration;