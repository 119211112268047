import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    isModalOpen: false,
    isLoading: true,
    error: null,
    selectedData: null,
    nas_types: [],

    nameError: '',


    currentPage: 1,
    nextPage: 0,
    prevPage: 0,
    lastPage: 0,
    isEdit: false,
};


export const nasTypeSlice = createSlice({
    name: "nas_types",
    initialState,
    reducers: {
        setIsLoading: (state, { payload }) => {
            state.isLoading = payload;
        },
        setNasType: (state, { payload }) => {
            state.nas_types = payload;
        },
        setError: (state, { payload }) => {
            state.error = payload;
        },

        
        setNameError: (state, { payload }) => {
            state.nameError = payload;
        },

        setIsModalOpen: (state, { payload }) => {
            state.isModalOpen = payload;
        },
        setSelectedData: (state, { payload }) => {
            state.selectedData = payload;
        },
        setCurrentPage: (state, { payload }) => {
            state.currentPage = payload;
        },
        setNextPage: (state, { payload }) => {
            state.nextPage = payload;
        },
        setPrevPage: (state, { payload }) => {
            state.prevPage = payload;
        },
        setLastPage: (state, { payload }) => {
            state.lastPage = payload;
        },
        setIsEdit: (state, { payload }) => {
            state.isEdit = payload;
        },

    }
})


export const { setIsLoading, setError, setNasType, setNameError, setIsModalOpen, setSelectedData, setCurrentPage, setLastPage, setNextPage, setPrevPage, setIsEdit } = nasTypeSlice.actions;
export default nasTypeSlice.reducer;

export const nasTypeSelector = (state) => state.nas_types;