import { Button, Flex, Input, Spinner, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/system';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import React from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleOpenModal } from 'redux/actions/NasAction';
import { getNas } from 'redux/actions/NasAction';
import { setCurrentPage } from 'redux/slices/Nas';
import NaseTableData from './NasTableRow';
import { handleEdit } from 'redux/actions/NasAction';
import { handleCloseModal } from 'redux/actions/NasAction';
import ModalNas from './NasModal';

const NasView = ({ title, captions }) => {
    const dispatch = useDispatch();
    const textColor = useColorModeValue("gray.700", "white");
    const configurationList = useSelector((state) => state.configuration);
    const {
        configuration,
        token
    } = configurationList;
    const nasList = useSelector((state) => state.nas);
    const {
        isModalOpen,
        isLoading,
        error,
        selectedData,
        nas,

        currentPage,
        nextPage,
        prevPage,
        lastPage,
        isEdit,
    } = nasList;

    // Get Nas List
    useEffect(() => {
        dispatch(getNas(currentPage, token));
    }, [currentPage, dispatch]);

    return (
        <>
            {
                isLoading ? ( // Display loading spinner if data is still being fetched
                    <Flex justify="center" align="center" height="300px">
                        <Spinner size="xl" color="gray.400" />
                    </Flex>
                ) : (
                    <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
                        <CardHeader p='6px 0px 22px 0px'>
                            <Flex alignItems='center' justifyContent='space-between' flex={1}>
                                <Text fontSize='xl' color={textColor} fontWeight='bold'>
                                    {title}
                                </Text>
                                <Button onClick={() => dispatch(handleOpenModal())} colorScheme='telegram' bgColor={configuration.color} mr='3px' color={"white"}>
                                    Add New Nas
                                </Button>
                            </Flex>
                        </CardHeader>
                        <CardBody>
                            <Table variant='simple' color={textColor}>
                                <Thead>
                                    <Tr>
                                        <Td colSpan={7} pl="0px">
                                            <Flex justifyContent={"flex-end"}>
                                                <Input
                                                    width={"30%"}
                                                    mr={"0.5rem"}
                                                    type="text"
                                                    placeholder="Search"
                                                />
                                                <Button
                                                    colorScheme="telegram"
                                                    bgColor={configuration.color}
                                                    color={"white"}
                                                >
                                                    Search
                                                </Button>
                                            </Flex>
                                        </Td>
                                    </Tr>

                                    <Tr my='.8rem' pl='0px' color='gray.400'>
                                        {captions.map((caption, idx) => {
                                            return (
                                                <Th color='gray.400' key={idx} ps={idx === 0 ? "0px" : null}>
                                                    {caption}
                                                </Th>
                                            );
                                        })}
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {nas.map((row, index) => {
                                        return (
                                            <NaseTableData
                                                key={row.id}
                                                id={row.id}
                                                index={index + 1}
                                                ip={row.ip}
                                                name={row.name}
                                                secret={row.secret}
                                                nas_type={row.nas_type_id}
                                                nas_type_name={row.nas_type}
                                                site={row.site_id}
                                                site_name={row.site_name}
                                                onEdit={handleEdit}
                                            />
                                        );
                                    })}
                                    <Tr>
                                        <Td colSpan={7} pl="0px">
                                            <Flex justifyContent="flex-end" alignItems="center">
                                                {prevPage !== false && <Button colorScheme='telegram' bgColor={configuration.color} mr='1rem' onClick={() => dispatch(setCurrentPage(currentPage - 1))}>
                                                    <Text
                                                        fontSize="md"
                                                        color="white"
                                                        fontWeight="bold"
                                                        cursor="pointer"
                                                    >
                                                        Prev
                                                    </Text>
                                                </Button>
                                                }
                                                <Text fontSize="md" color={textColor} fontWeight="bold" pr="1rem">
                                                    Page No: {currentPage}
                                                </Text>
                                                {nextPage !== false && <Button colorScheme='telegram' bgColor={configuration.color} onClick={() => dispatch(setCurrentPage(currentPage + 1))}>
                                                    <Text
                                                        fontSize="md"
                                                        color="white"
                                                        fontWeight="bold"
                                                        cursor="pointer"
                                                    >
                                                        Next
                                                    </Text>
                                                </Button>
                                                }
                                            </Flex>
                                        </Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </CardBody>
                        {isModalOpen && <ModalNas isOpen={isModalOpen} onClose={handleCloseModal} initialData={selectedData} isEdit={isEdit} />}
                    </Card>
                )
            }
        </>
    )
}

export default NasView