import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    isModalOpen: false,
    isLoading: true,
    error: null,
    selectedData: null,
    sites: [],

    selectedParent: '',
    parentError: '',
    parentOptions: [],

    nameError: '',


    currentPage: 1,
    nextPage: 0,
    prevPage: 0,
    lastPage: 0,
    isEdit: false,
};


export const sitesSlice = createSlice({
    name: "sites",
    initialState,
    reducers: {
        setIsLoading: (state, { payload }) => {
            state.isLoading = payload;
        },
        setSites: (state, { payload }) => {
            state.sites = payload;
        },
        setError: (state, { payload }) => {
            state.error = payload;
        },

        setParentOptions: (state, { payload }) => {
            state.parentOptions = payload;
        },
        setSelectedParent: (state, { payload }) => {
            state.selectedParent = payload;
        },
        setParentError: (state, { payload }) => {
            state.parentError = payload;
        },

        setNameError: (state, { payload }) => {
            state.nameError = payload;
        },

        setIsModalOpen: (state, { payload }) => {
            state.isModalOpen = payload;
        },
        setSelectedData: (state, { payload }) => {
            state.selectedData = payload;
        },
        setCurrentPage: (state, { payload }) => {
            state.currentPage = payload;
        },
        setNextPage: (state, { payload }) => {
            state.nextPage = payload;
        },
        setPrevPage: (state, { payload }) => {
            state.prevPage = payload;
        },
        setLastPage: (state, { payload }) => {
            state.lastPage = payload;
        },
        setIsEdit: (state, { payload }) => {
            state.isEdit = payload;
        },

    }
})


export const { setIsLoading, setError, setSites, setParentOptions, setSelectedParent, setParentError, setNameError, setIsModalOpen, setSelectedData, setCurrentPage, setLastPage, setNextPage, setPrevPage, setIsEdit } = sitesSlice.actions;
export default sitesSlice.reducer;

export const sitesSelector = (state) => state.sites;