import axios from 'axios';

import {
    setType,
    setParentOptions,
    setTypeOptions,
    setDisableParent,
    setSelectedParent,
    setTypeName,
    setNameError,
    setTypeError,
    setParentError
} from '../slices/AreaModalSlice';

import { getAreas } from './AreaAction';
import { baseurl } from 'variables';
// baseurl
// getAreas
export const fetchTypeAreas = (token) => async (dispatch) => {
    try {
        const response = await axios.get(`${baseurl}/area-type`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log('response', response);
        const typeOptions = response.data.data.area_type.map((type) => ({
            value: type.value,
            label: type.type,
        }));
        dispatch(setTypeOptions(typeOptions));
    } catch (error) {
        dispatch(setTypeOptions([]));
        console.log('error', error);
    }
};

export const fetchParentAreas = (type, token) => async (dispatch) => {
    try {
        if (!type || type.value === 0) {
            dispatch(setDisableParent(true));
            dispatch(setParentOptions([]));
            dispatch(setSelectedParent(''));
        } else {
            const response = await axios.post(
                `${baseurl}/specific-type-area?type=${parseInt(type.value)-1}`,{},
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
            );
            console.log('parent response', response, )
            const parentOptions = response.data.data.area.map((parent) => ({
                value: parent.id,
                label: parent.name,
            }));
            dispatch(setParentOptions(parentOptions));
            dispatch(setDisableParent(false));
        }
    } catch (error) {
        dispatch(setParentOptions([]));
        dispatch(setSelectedParent(''));
        console.log(error);
    }
};


export const handleTypeChange = (selectedTypeOption, typeOptions) => async (dispatch) => {
    const selectedType = selectedTypeOption;
    dispatch(setType(selectedType));
    dispatch(setSelectedParent(''));
    dispatch(setParentOptions([]));
    console.log('on change type', selectedType, typeOptions, typeOptions.find((option) => option.value === selectedTypeOption.value-1))
    if (!selectedType || selectedType.value === 0) {
        dispatch(setDisableParent(true));
        dispatch(setTypeName(''));
        dispatch(setTypeError(''));
    } else {
        
        dispatch(setTypeName(typeOptions.find((option) => option.value === selectedTypeOption.value-1).label));
    }
};

export const handleParentChange = (selectedOption) => async (dispatch) => {
    dispatch(setSelectedParent(selectedOption));
    dispatch(setParentError(''));
};


export const handleSave = (selectedParent, type, isEdit, initialData, onClose, token) => async (dispatch) => {
    try {
        const name = document.getElementsByName('name')[0].value;


        if (!name) {
            dispatch(setNameError('Name is required'));
            return;
        } else {
            dispatch(setNameError(''));
        }
        let formData = {
            name: name,
        };

        if (!type) {
            dispatch(setTypeError(`Type is required`));
            return;
        } else {
            dispatch(setTypeError(''));
            formData = {
                ...formData,
                type: type.value,
            };
        }

        if (type.value !== 0) {
            if (!selectedParent) {
                dispatch(setParentError(`${typeName} is required`));
                return;
            } else {
                dispatch(setParentError(''));
                formData = {
                    ...formData,
                    area_parent_id: selectedParent.value,
                };
            }
        }

        console.log('save response', formData, isEdit, initialData);
        if (isEdit && initialData) {
            formData = {
                ...formData,
                id: initialData.id,
            };
            const response = await axios.post(`${baseurl}/add-area`, formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            // Handle the response or perform any other necessary actions
            console.log(response.data);
        } else {
            const response = await axios.post(`${baseurl}/add-area`, formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            // Handle the response or perform any other necessary actions
            console.log(response.data);
        }

        // window.location.reload();
        dispatch(getAreas(1, token));
        dispatch(onClose());
    } catch (error) {
        console.log(error);
    }
};