import axios from 'axios';
import {
    setConfiguration,
    setUser,
    setToken,
    setUsernameError,
    setPasswordError
} from '../slices/ConfigurationSlice';

import { baseurl } from 'variables';


export const getConfiguration = () => async (dispatch) => {
    try {
        const response = await axios.get(`${baseurl}/get-color-scheme/?url=${window.location.host}`);
        console.log('response color scheme', response.data.data.color_scheme);
        const jsonData = JSON.stringify(response.data.data.color_scheme);
        localStorage.setItem('configuration', jsonData);
        dispatch(setConfiguration(response.data.data.color_scheme));
    } catch (error) {
        dispatch(setConfiguration(null));
        console.log('error', error);
    }
};

export const SignoutApi = (token) => async (dispatch) => {
    try {
        const response = await axios.get(`${baseurl}/logout`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        console.log('Sign out', response)
        localStorage.removeItem('token');
        dispatch(setToken(null));

        localStorage.removeItem('user');
        dispatch(setUser(null));
        window.location.reload();

    }
    catch (error) {
        console.log('Error with Sign Out', error, token)
    }
}

export const SigninApi = (toast) => async (dispatch) => {
    let response;
    try {
        const username = document.getElementsByName('username')[0].value;
        const password = document.getElementsByName('password')[0].value;

        if (!username) {
            dispatch(setUsernameError('Username is required'));
            return;
        } else {
            dispatch(setUsernameError(''));
        }

        if (!password) {
            dispatch(setPasswordError('Password is required'));
            return;
        } else {
            dispatch(setPasswordError(''));
        }
        const formdata = {
            username: username,
            password: password,
            domain: window.location.origin
        }

        response = await axios.post(`${baseurl}/login`, formdata);
        console.log('response color scheme', response, Object.keys(response.data.data.user).length, formdata, window);
        if (Object.keys(response.data.data.user).length !== 0) {

            const token = JSON.stringify(response.data.data.user.token);
            localStorage.setItem('token', token);
            dispatch(setToken(token));

            const jsonData = JSON.stringify(response.data.data.user);
            localStorage.setItem('user', jsonData);
            dispatch(setUser(response.data.data.user));
            window.location.reload();
            toast({
                title: "Success",
                description: "Singin Successfully.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        }
        else {
            dispatch(setUser(null));
            dispatch(setToken(null));
            console.log('error', response.data.message);
            toast({
                title: "Singin Error",
                description: response.data.message,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    } catch (error) {
        dispatch(setUser(null));
        dispatch(setToken(null));
        console.log('error', error);
        toast({
            title: "SingIn Error",
            description: error,
            status: "error",
            duration: 3000,
            isClosable: true,
        });
    }
};

