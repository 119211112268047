import React, { useState } from "react";
// Chakra imports
import {
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  chakra,
  Input,
  Switch,
  Text,
  useColorModeValue,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  FormErrorMessage,
  useToast,
  IconButton,
} from "@chakra-ui/react";
import { FaUserAlt, FaLock } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getConfiguration } from "redux/actions/ConfigurationAction";
import { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { SigninApi } from "redux/actions/ConfigurationAction";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

function SignIn() {
  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory();

  const [showPassword, setShowPassword] = useState(false);
  const handleShowClick = () => setShowPassword(!showPassword);
  const CFaUserAlt = chakra(FaUserAlt);
  const CFaLock = chakra(FaLock);
  const configurationList = useSelector((state) => state.configuration);
  const { configuration, usernameError, passwordError, token } = configurationList;

  useEffect(() => {
    dispatch(getConfiguration());
  }, []);

  useEffect(() => {
    if (token !== null) {
      history.push('/admin/dashboard');
    }
  }, []);
  const confTextColor = !configuration.color ? "teal.200" : configuration.color
  const titleColor = useColorModeValue(confTextColor, "teal.200");
  const textColor = useColorModeValue("gray.400", "white");
  const backgroundImage = configuration.background
    ? `${configuration.base_url}${configuration.background}`
    : "https://htmlcolorcodes.com/assets/images/colors/black-color-solid-background-1920x1080.png";

  return (
    <Flex
      h="100vh"
      alignItems="center"
      justifyContent="center"
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundImage={`url(${backgroundImage})`}>
      <Flex
        bgColor="blackAlpha.600"a
        direction="column"
        borderRadius={'xl'}
        w={{ base: "100%", md: "25%", lg: "25%" }}
        p="48px"
      >
        <Heading color={confTextColor} fontSize="32px" mb="30px" style={{ textStroke: "0.3px white", WebkitTextStroke: "0.3px white" }}>
          Welcome Back
        </Heading>
        <FormControl isRequired isInvalid={usernameError !== ''}>
          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
            Username
          </FormLabel>
          <Input
            borderRadius="15px"
            mb="24px"
            name="username"
            bgColor={'whiteAlpha.500'}
            fontSize="sm"
            type="text"
            placeholder="Enter Username"
            size="md"
          />
          <FormErrorMessage>{usernameError}</FormErrorMessage>

        </FormControl>
        <FormControl isRequired isInvalid={passwordError !== ''}>

          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
            Password
          </FormLabel>
          <InputGroup size='md'>
            <Input
              pr='4.5rem'
              size="md"
              borderRadius="15px"
              mb="24px"
              bgColor={'whiteAlpha.500'}
              // color={confTextColor}
              name="password"
              type={showPassword ? 'text' : 'password'}
              placeholder='Enter password'
              placeholderTextColor='red'
            />
            <InputRightElement>
              {/* <Button bg={confTextColor}
                mt={1.5}
                h='1.75rem'
                size='sm'
                _hover={{
                  bg: "teal.200",
                }}
                _active={{
                  bg: "teal.400",
                }} >
                { ? 'Hide' : 'Show'}
              </Button> */}

              <IconButton
                icon={showPassword ? <ViewOffIcon size='lg' /> : <ViewIcon size='lg' />}
                onClick={handleShowClick}
                mt={1.5}
                _hover={{
                  bgColor: 'transparent',
                }}
                _active={{
                  bgColor: 'transparent',
                }}
                bgColor={'transparent'}
                aria-label="View Secret"
              />

            </InputRightElement>
          </InputGroup>
          <FormErrorMessage>{passwordError}</FormErrorMessage>
        </FormControl>
        <Button
          fontSize="10px"
          type="submit"
          bg={confTextColor}
          w="100%"
          h="45"
          mb="20px"
          size="md"
          onClick={() => dispatch(SigninApi(toast))}
          color="white"
          mt="20px"
          _hover={{
            bg: "teal.200",
          }}
          _active={{
            bg: "teal.400",
          }}
        >
          SIGN IN
        </Button>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          maxW="100%"
          mt="0px"
        >
          <Text color={textColor} fontWeight="medium">
            Don't have an account?&nbsp;
            <Link to="/auth/signup" style={{ color: titleColor }} ms="5px" fontWeight="bold">
              Sign Up
            </Link>
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default SignIn;
