// Chakra imports
import { Flex } from "@chakra-ui/react";
import React from "react";
import SiteView from "./components/SiteView";
// import Authors from "./components/Authors";

function Sites() {

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <SiteView
          title={"Sites"}
          captions={["Sr.", "Name", "Type", "Area", "actions"]}
        />
    </Flex>
  );
}

export default Sites;
