import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    type: null,
    parentOptions:[],
    typeOptions:[],
    disableParent: true,
    selectedType: '',
    selectedParent: '',
    typeName: 'Province',
    nameError: '',
    typeError: '',
    parentError: '',

};
export const areasModalSlice =  createSlice({
    name: "areas_modal",
    initialState,
    reducers: {
        setType : (state, {payload}) => {
            state.type = payload;
        },
        setParentOptions : (state, {payload}) => {
            state.parentOptions = payload;
        },
        setTypeOptions : (state, {payload}) => {
            state.typeOptions = payload;
        },
        setDisableParent : (state, {payload}) => {
            state.disableParent = payload;
        },
        setSelectedType : (state, {payload}) => {
            state.selectedParent = payload;
        },
        setSelectedParent : (state, {payload}) => {
            state.selectedParent = payload;
        },
        setTypeName : (state, {payload}) => {
            state.typeName = payload;
        },
        setNameError : (state, {payload}) => {
            state.nameError = payload;
        },
        setTypeError : (state, {payload}) => {
            state.typeError = payload;
        },
        setParentError : (state, {payload}) => {
            state.parentError = payload;
        },
    }
})
export const { setType, setParentOptions,  setTypeOptions, setDisableParent, setSelectedType, setSelectedParent, setTypeName, setNameError, setTypeError, setParentError } = areasModalSlice.actions;
export default areasModalSlice.reducer;

export const areasModalSelector = (state) => state.areas_modal;